
import { defineComponent, ref } from "vue";
import { getDealerInfo } from "@/API/rv/rvControlling";
export default defineComponent({
  props: {},
  setup() {
    const data = ref({});
    const initData = () => {
      const params = {
        id: Number(sessionStorage.getItem("controllingRecordId"))
      };
      getDealerInfo({ params }).then((res): void => {
        data.value = res;
      });
    };
    initData();
    return {
      data
    };
  }
});
