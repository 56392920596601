import { fetchGet, fetchPost, fetchGetMess } from '../index';
import {ControllingFailReasonData, ControllingStatusData, SelectData} from '@/views/RetailValidation/Controlling/types';
export const getBasicDetail = (params = {}): Promise<any> => {
    return fetchGet('/rvapi/basic/finance/retail/retailInfo', params)
}
export const getOperRecordDetail = (params = {}): Promise<any> => {
    return fetchGet('/rvapi/basic/retail/operationDetails', params)
}
export const getRVTable = (params = {}): Promise<any> => {
    return fetchPost('/rvapi/basic/finance/retail/mulquery', params)
}
export const getFailReasonData = (params = {}): Promise<ControllingFailReasonData[]> => {
  return fetchGet('/rvapi/basic/retail/failReasonView', params)
}
export const getRetailHistory = (params = {}): Promise<any> => {
  return fetchGetMess('/saleapi/noAuth/rt/findByVin', params);
};
export const getlicenseView = (params = {}): Promise<string> => {
  return fetchGet('/rvapi/basic/certificate/licenseView', params)
}
export const getInvioceView = ( params = {}): Promise <string> => {
  return fetchGet('/rvapi/basic/certificate/invoiceView',params)
}
export const getApprovalStatus = (): Promise<ControllingStatusData[]> => {
  return fetchGet('/rvapi/stand/approvalStatus/dicSicco')
}
export const getDealerCName = (): Promise<ControllingStatusData[]> => {
  return fetchGet('/rvapi/stand/approvalStatus/dicDealer')
}
export const getAreaArray = (): Promise<ControllingStatusData[]> => {
  return fetchGet('/rvapi/stand/approvalStatus/dicDealer')
}
export const getCheckStatus = (): Promise<ControllingStatusData[]> => {
  return fetchGet('/rvapi/stand/checkStatus/dicSicco')
}
export const searchDealerName = (params = {}): Promise<any> => {
  return fetchGet('/rvapi/basic/retail/searchDealerName', params)
}
export const getDealerInfo = (params = {}): Promise<string> => {
  return fetchGet('/rvapi/basic/apply/dealer/dealerInfo', params)
}
export const getSelectData = (): Promise<SelectData> => {
  return fetchGet('/rvapi/basic/data/mainData')
}