
import { defineComponent, ref } from "vue";
import { getBasicDetail } from "@/API/rv/rvControlling";
import {
  EnumControllingApprovalStatus,
  EnumControllingCheckStatus
} from "@/views/RetailValidation/Controlling/types";
export default defineComponent({
  setup() {
    const data = ref({});
    const initData = () => {
      const params = {
        id: sessionStorage.getItem("controllingRecordId")
      };
      getBasicDetail({ params }).then((res): void => {
        data.value = res;
      });
    };
    initData();
    return {
      data,
      EnumControllingApprovalStatus,
      EnumControllingCheckStatus
    };
  }
});
