
import { defineComponent, ref, toRefs, nextTick } from "vue";
import useControllingOverview from "@/hooks/rv/userControllingOverview";
import useDealerOverview from "@/hooks/rv/useDealerOverview";
import useSiccoOverview from "@/hooks/rv/useSiccoOverview";
import downloadFile from "@/utils/rv/downloadFile";
import FailReason from "@/views/RetailValidation/Controlling/FailReason/index.vue";
import RetailValidationDetail from "@/views/RetailValidation/Controlling/RetailValidationDetail/index.vue";
import {
  ControllingTableData,
  EnumControllingApprovalStatus,
  EnumControllingCheckStatus,
} from "@/views/RetailValidation/Controlling/types";
import cleanEmptyEleArray from "@/utils/rv/cleanEmptyElement";
import { message } from "ant-design-vue";
import Preview from "@/views/RetailValidation/component/Preview.vue";
import { getSpecialUsageItem } from "@/API/rv/rvDealer";
const usageArray = ["最终用户用车", "经销商用车", "全部"];
const specialUsageArray = ref<any>([]);
const entityArray = ["FBAC", "BBAC", "MBCL", "全部"];
const brandArray = ["MB", "AMG", "EQ", "VAN", "DENZA", "Maybach", "全部"];
const tableHeight = window.innerHeight - 310;
const tableWidth = window.innerWidth;
export default defineComponent({
  components: {
    RetailValidationDetail,
    FailReason,
    Preview,
  },
  setup() {
    // 详情弹窗是否显示
    const isDetailVisible = ref<boolean>(false);
    const entityData = ref<string>("");
    const {
      pictureModal,
      licenseEvent,
      invoiceEvent,
      closePictureModal,
    } = useDealerOverview();
    const {
      queryParams,
      isOpenPanle,
      isOpen,
      tableData,
      total,
      activeKey,
      resetEvent,
      mulSearchEvent,
      showFailFlag,
      pageChange,
      sizeChange,
      failData,
      viewReasonEvent,
      handleClick,
      handleActiveKey,
      showCollapsed,
      columns,
      batchQuery,
      approvalStatusArray,
      rebateFlagArray,
      dateChangeEvent,
      SelectData,
    } = useControllingOverview();
    const {
      dealerNameEnArray,
      swtArray,
      coficoArray,
      dealerNameCnArray,
      handleSearch,
    } = useSiccoOverview();
    // 批量查询文本框
    const input = ref();
    const textContentNo = ref<string>("");
    const textNoArray = ref<string[]>([]);
    const batchPlaceholder = ref(["", "", "", "", "", ""]);
    // 设置批量查询modal 的 textNoArray
    const setBatchQueryTextNoArray = () => {
      if (batchQuery.batchQueryTitle === "车架号批量查询") {
        textNoArray.value =
          cleanEmptyEleArray(queryParams.vinNos)[0] === ""
            ? []
            : cleanEmptyEleArray(queryParams.vinNos);
      } else {
        textNoArray.value =
          cleanEmptyEleArray(queryParams.commissionNos)[0] === ""
            ? []
            : cleanEmptyEleArray(queryParams.commissionNos);
      }
    };
    // 设置批量查询modal 的 占位div
    const setBatchPlaceholder = () => {
      if (textNoArray.value.length) {
        batchPlaceholder.value = [];
      } else {
        batchPlaceholder.value = ["", "", "", "", "", ""];
      }
    };
    // 批量查询按钮事件
    const batchQueryEvent = (arg: string) => {
      if (arg === "vinNo") {
        batchQuery.batchQueryModal = true;
        batchQuery.batchQueryTitle = "车架号批量查询";
        batchQuery.batchQueryListTitle = "车架号列表";
      } else {
        batchQuery.batchQueryModal = true;
        batchQuery.batchQueryTitle = "生产编号批量查询";
        batchQuery.batchQueryListTitle = "生产编号列表";
      }
      setBatchQueryTextNoArray();
      setBatchPlaceholder();
      const dom = document.getElementsByClassName("list-content")[0];
      nextTick(() => {
        dom && dom.scrollTo(0, textNoArray.value.length * 36);
        input.value.focus();
      });
    };
    // 批量文本内容
    const textChangEvent = () => {
      if (textContentNo.value.length < 5) {
        message.error("请从Excel中粘贴到文本框");
        textContentNo.value = "";
        return;
      }
      textNoArray.value = textNoArray.value.concat(
        textContentNo.value
          .replace(/\r|\n|\s/g, ",")
          .split(",")
          .filter((item) => item)
      );
      const dom = document.getElementsByClassName("list-content")[0];
      textContentNo.value = "";
      if (batchQuery.batchQueryTitle === "车架号批量查询") {
        queryParams.vinNos = [];
        queryParams.vinNos = queryParams.vinNos.concat(textNoArray.value);
      } else {
        queryParams.commissionNos = [];
        queryParams.commissionNos = queryParams.commissionNos.concat(
          textNoArray.value
        );
      }
      setBatchPlaceholder();
      nextTick(() => {
        dom.scrollTo(0, dom.scrollHeight + 40);
        input.value.focus();
      });
    };
    // 关闭批量查询Modal
    const closeBatchQueryModal = () => {
      queryParams.vinNo = queryParams.vinNos[0];
      queryParams.commissionNo = queryParams.commissionNos[0];
    };
    // 清除批量查询
    const clearBatchQuery = () => {
      if (batchQuery.batchQueryTitle === "车架号批量查询") {
        queryParams.vinNos = [""];
      } else {
        queryParams.commissionNos = [""];
      }
      setBatchQueryTextNoArray();
      setBatchPlaceholder();
      input.value.focus();
    };
    const exportResult = () => {
      const params = {
        url: `/rvapi/basic/finance/retail/resultExport`,
        method: "post",
        params: queryParams,
        fileName: `${+new Date()}.xlsx`,
      };
      downloadFile(params);
    };
    // 查看详情
    const detailEvent = (record: ControllingTableData) => {
      sessionStorage.setItem("controllingRecordId", String(record.id));
      sessionStorage.setItem("controllingRecordEntity", record.entity);
      sessionStorage.setItem("controllingRecordVinNo", record.vinNo);
      isDetailVisible.value = true;
    };
    // 关闭弹窗
    const closeDetail = (param: { isVisible: boolean }) => {
      isDetailVisible.value = param.isVisible;
    };
    // 关闭失败原因弹窗
    const closeFailReason = () => {
      showFailFlag.value = false;
    };
    //获取特殊车辆
    const getSpecialUsageArray = () => {
      getSpecialUsageItem().then((res) => {
        res.forEach((item: any) => {
          specialUsageArray.value.push(item.specialUsageCn);
        });
      });
    };
    getSpecialUsageArray();
    return {
      isOpen,
      isOpenPanle,
      showFailFlag,
      columns,
      activeKey,
      handleClick,
      handleActiveKey,
      showCollapsed,
      ...toRefs(batchQuery),
      ...toRefs(queryParams),
      ...toRefs(pictureModal),
      ...toRefs(SelectData),
      pictureModal,
      tableData,
      mulSearchEvent,
      usageArray,
      specialUsageArray,
      entityArray,
      approvalStatusArray,
      rebateFlagArray,
      total,
      pageChange,
      sizeChange,
      queryParams,
      viewReasonEvent,
      exportResult,
      detailEvent,
      isDetailVisible,
      closeDetail,
      closeFailReason,
      failData,
      entityData,
      resetEvent,
      licenseEvent,
      invoiceEvent,
      tableHeight,
      tableWidth,
      batchQueryEvent,
      textContentNo,
      textChangEvent,
      textNoArray,
      batchPlaceholder,
      EnumControllingCheckStatus,
      EnumControllingApprovalStatus,
      clearBatchQuery,
      closeBatchQueryModal,
      dealerNameEnArray,
      swtArray,
      coficoArray,
      dealerNameCnArray,
      handleSearch,
      input,
      dateChangeEvent,
      closePictureModal,
      brandArray,
    };
  },
});
