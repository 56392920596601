import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_descriptions_item = _resolveComponent("a-descriptions-item")!
  const _component_a_descriptions = _resolveComponent("a-descriptions")!

  return (_openBlock(), _createBlock(_component_a_descriptions, {
    column: 1,
    bordered: "",
    class: "infoDescription"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_descriptions_item, { label: "经销商代码(cofico)：" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.data.dealerCoficoCode), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_a_descriptions_item, { label: "经销商代码(SWT)：" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.data.dealerSWTCode), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_a_descriptions_item, { label: "经销商中文名称：" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.data.dealerNameCn), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_a_descriptions_item, { label: "经销商英文名称：" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.data.dealerNameEn), 1)
        ]),
        _: 1
      }),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.poccMap, (value, label) => {
        return (_openBlock(), _createBlock(_component_a_descriptions_item, {
          label: `${label}:`,
          key: label
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(value), 1)
          ]),
          _: 2
        }, 1032, ["label"]))
      }), 128))
    ]),
    _: 1
  }))
}