import { ref, reactive } from "vue";
import { ControllingTableData, ControllingFailReasonData, ControllingStatusData }
  from "@/views/RetailValidation/Controlling/types";
import * as API from "@/API/rv/rvControlling";
import setColumnWidth from "@/utils/rv/setColumnWidth"
import cleanEmptyEleArray from "@/utils/rv/cleanEmptyElement"
import { resetParam, resetPatrial } from '@/utils/rv/configParam'
import { useStore } from "vuex";
const useControllingOverview = () => {
  // 打开筛选条件
  const isOpen = ref<boolean>(false)
  // 筛选面板
  const activeKey = ref<string>("")
  // 校验列表
  const tableData = ref<ControllingTableData[]>([])
  const total = ref<number>(0)
  // 失败原因列表
  const failData = ref<ControllingFailReasonData[]>([])
  // 是否显示失败原因面板
  const showFailFlag = ref<boolean>(false)
  // 状态下拉框
  const approvalStatusArray = ref<ControllingStatusData[]>([])
  // 激励适用性
  const rebateFlagArray = ref<ControllingStatusData[]>([])
  // 批量查询
  const batchQuery = reactive({
    batchQueryTitle: '',
    batchQueryModal: false,
    batchQueryListTitle: ''
  })
  // 多维查询下拉框备选项
  const SelectData = reactive({
    typeClassArray: [],
    modelYearArray: [],
    powerTrainArray: [],
    areasArray: []
  })
  const columns = reactive([
    { title: "车架号", dataIndex: "vinNo", width: 168, fixed: 'left' },
    { title: "车型", dataIndex: "model", key: "model", width: 60 },
    { title: "经销商中文名称", dataIndex: "dealerNameCn", key: "dealerNameCn", width: 150 },
    { title: "经销商代码 (cofico)", dataIndex: "dealerCofficoCode", width: 150 },
    { title: "经销商代码 (SWT)", dataIndex: "dealerSwtCode", width: 150, key: "dealerSwtCode" },
    { title: "区域", dataIndex: "region", width: 90, key: "region" },
    { title: "客户名称", dataIndex: "customerName", width: 100 },
    { title: "零售类型", dataIndex: "usage", width: 110, key: "usage" },
    { title: "零售日期", dataIndex: "retailDate", width: 90, key: "retailDate" },
    { title: "特殊车辆用途", dataIndex: "specialUsage", width: 116, key: "specialUsage", },
    { title: "提交状态", dataIndex: "approlStatus", width: 90, key: "approlStatus", slots: { customRender: "approlStatus" } },
    {
      title: "激励适用性", dataIndex: "checkStatus", width: 104,
      key: "checkStatus", slots: { customRender: "checkStatus" }
    },
    { title: "品牌", dataIndex: "brand", width: 80 },
    { title: "失败原因", key: "fail", fixed: 'right', width: 90, slots: { customRender: "fail" } },
    { title: "文件", key: "file", fixed: 'right', width: 120, slots: { customRender: "file" } },
    {
      title: "操作", key: "operation", fixed: 'right', width: 60, slots: { customRender: "action" }
    },
  ])
  // 收起按钮显示位置 false 在折叠面板的header
  const showCollapsed = ref<boolean>(false)
  // 打开筛选条件面板
  const isOpenPanle = ref<boolean>(false)
  // 发票/行驶证 查看弹窗
  const pictureModal = reactive({
    pictureModalFlag: false,
    pictureModalTitle: "",
    pictureModalImg: "",
  });
  // 筛选条件
  const queryParams = reactive({
    vinNo: "",
    model: "",
    retailStartDate: "",
    retailEndDate: "",
    usage: "",
    commissionNo: "",
    typeClass: "",
    modelYear: "",
    entity: "",
    specialUsage: "",
    powerTrain: "",
    invoiceStartDate: "",
    invoiceEndDate: "",
    licenseStartDate: "",
    licenseEndDate: "",
    approlStatus: "",
    brand: "",
    checkStatus: "",
    region: "",
    commissionNos: [''],
    dealerCofficoCode: '',
    dealerSwtCode: '',
    vinNos: [''],
    dealerNameCn: '',
    dealerNameEn: '',
    current: 1,
    size: 20,
  });
  // 模糊查询经销商名称
  const dealerNameRes = ref([]);
  const resetEvent = () => {
    resetPatrial(queryParams)
    queryParams.commissionNos = [''],
      queryParams.vinNos = [''],
      queryParams.current = 1,
      queryParams.size = 20
  };
  const dateChangeEvent = (date: string[], arg: string) => {
    switch (arg) {
      case 'retail':
        queryParams.retailStartDate = date[0]
        queryParams.retailEndDate = date[1]
        break
      case 'invoice':
        queryParams.invoiceStartDate = date[0]
        queryParams.invoiceEndDate = date[1]
        break
      case 'license':
        queryParams.licenseStartDate = date[0]
        queryParams.licenseEndDate = date[1]
        break
    }
  }
  const getTableData = () => {
    queryParams.vinNo = queryParams.vinNo.trim()
    queryParams.commissionNo = queryParams.commissionNo.trim()
    queryParams.model = queryParams.model.trim()
    queryParams.dealerNameCn = queryParams.dealerNameCn.trim()
    queryParams.dealerNameEn = queryParams.dealerNameEn.trim()
    queryParams.vinNos = cleanEmptyEleArray(queryParams.vinNos)
    queryParams.commissionNos = cleanEmptyEleArray(queryParams.commissionNos)
    API.getRVTable(queryParams).then((res) => {
      tableData.value = res.content;
      total.value = res.totalElements;
      queryParams.size = res.size
      const customerColumn = ['model', 'dealerNameCn', 'customerName', 'specialUsage', 'brand', 'retailDate']
      tableData.value.length && Object.assign(columns, setColumnWidth(columns, tableData.value, customerColumn))
    });
  }
  API.getApprovalStatus().then(res => {
    approvalStatusArray.value = res
    approvalStatusArray.value.push(
      { code: "全部", msg: "全部" }, // '全部' 根据story添加一个状态
    )
  })
  API.getCheckStatus().then(res => {
    rebateFlagArray.value = res
    // '全部' 根据story添加一个状态
    rebateFlagArray.value.push({ code: "全部", msg: "全部" })
  })
  API.getSelectData().then((res: any) => {
    const newModelYearArray: any = [];
    const newpowerTrainArray: any = [];
    const newtypeClassArray: any = [];
    const newareasArray: any = []
    res.modelYear.forEach((item: any) => {
      if (item.msg !== "" && item.code !== "") {
        newModelYearArray.push(item);
        SelectData.modelYearArray = newModelYearArray;
      }
    })
    res.powerTrain.forEach((item: any) => {
      if (item.msg !== "" && item.code !== "") {
        newpowerTrainArray.push(item);
        SelectData.powerTrainArray = newpowerTrainArray;
      }
    })
    res.typeClass.forEach((item: any) => {
      if (item.msg !== "" && item.code !== "") {
        newtypeClassArray.push(item);
        SelectData.typeClassArray = newtypeClassArray;
      }
    })
    Array.prototype.concat(res.salesRegion).forEach((item: any) => {
      if (item.msg !== "" && item.code !== "") {
        newareasArray.push(item);
        SelectData.areasArray = newareasArray;
      }
    })
  })

  const mulSearchEvent = () => {
    resetParam(queryParams)
    queryParams.current = 1
    isOpenPanle.value = false
    isOpen.value = false
    activeKey.value = ""
    getTableData()
  }

  const licenseEvent = (vinNo: string, dealerCode: string) => {
    const params = { vinNo, dealerCode };
    API.getlicenseView({ params }).then(res => {
      pictureModal.pictureModalFlag = true;
      pictureModal.pictureModalTitle = "RE_" + vinNo
      pictureModal.pictureModalImg = res;
    });
  };
  const invoiceEvent = (vinNo: string, dealerCode: string) => {
    const params = { vinNo, dealerCode };
    API.getInvioceView({ params }).then(res => {
      pictureModal.pictureModalFlag = true;
      pictureModal.pictureModalImg = res;
      pictureModal.pictureModalTitle = "IN_" + vinNo;
    });
  }

  const pageChange = (page: number, pageSize: number) => {
    queryParams.current = page > 0 ? page : 1
    queryParams.size = pageSize;
    getTableData()
  };
  const sizeChange = (page: number, pageSize: number) => {
    queryParams.current = page > 0 ? page : 1
    queryParams.size = pageSize;
    getTableData()
  };
  const viewReasonEvent = (vinNo: string, dealerCode: string) => {
    const params = { vinNo, dealerCode };
    API.getFailReasonData({ params }).then((res) => {
      failData.value = res;
      showFailFlag.value = true;
    });
  };
  const handleClick = (event: MouseEvent) => {
    isOpenPanle.value = false;
    activeKey.value = ""
    event.stopPropagation();
  };
  const handleActiveKey = () => {
    if (activeKey.value.indexOf("4") !== -1) {
      showCollapsed.value = true;
    } else {
      showCollapsed.value = false;
    }
  };
  return {
    getTableData,
    resetEvent,
    mulSearchEvent,
    pageChange,
    sizeChange,
    licenseEvent,
    invoiceEvent,
    viewReasonEvent,
    handleClick,
    handleActiveKey,
    dateChangeEvent,
    columns,
    failData,
    queryParams,
    isOpenPanle,
    isOpen,
    tableData,
    total,
    pictureModal,
    activeKey,
    showFailFlag,
    showCollapsed,
    batchQuery,
    approvalStatusArray,
    rebateFlagArray,
    dealerNameRes,
    SelectData
  }
}
export default useControllingOverview
